<template>
  <div class="task-info-root-container">
    <div class="task-info-header-container">
      <span style="font-size: 15px; font-weight: bold">任务描述</span>
      <img
        @click="edit"
        style="width: 23px; height: 23px; cursor: pointer"
        :src="icons.edit"
      />
    </div>
    <div class="task-info-desc-container">
      <span style="word-break: break-all;">{{ taskDetail.description }}</span>
    </div>

    <span style="font-size: 15px; font-weight: bold; margin: 30px 0"
      >报酬方式</span
    >
    <span style="margin-right: 10px">{{ RewardTypeFormat }}</span>
    <Button
      type="text"
      style="color: #3b77bf; width: 28px; padding: 0"
      @click="updateType"
      >修改</Button
    >

    <span style="font-size: 15px; font-weight: bold; margin-top: 30px"
      >地址</span
    >
    <div class="task-info-address-container">
      <div
        v-for="(item, index) in taskDetail.address_arr"
        :key="index"
        class="task-info-address"
      >
        <Icon type="md-pin" />
        <span style="margin-left: 5px; margin-right: 15px">{{ item }}</span>
        <Icon type="md-close" @click="deleteAddress(index)" />
      </div>
    </div>
    <Button
      type="text"
      style="color: #3b77bf; width: 28px; padding: 0"
      @click="addAddress"
      >添加</Button
    >

    <span style="font-size: 15px; font-weight: bold; margin-top: 30px"
      >起止日期</span
    >
    <div class="task-info-row" style="margin-top: 30px">
      <span style="margin-right: 10px">自</span>
      <span style="font-weight: bold">{{ taskDetail.start_time }}</span>
      <span style="margin-right: 10px; margin-left: 10px">至</span>
      <span style="font-weight: bold">{{ taskDetail.end_time }}</span>
    </div>
    <task-info-drawer ref="taskInfoDrawer" @refresh="getDetail" />
    <task-type-drawer ref="taskTypeDrawer" @refresh="getDetail" />
    <Modal v-model="addressModalVisiable" @on-ok="addAddressComplete">
      <Input
        v-model="address"
        placeholder="请输入地址..."
        style="width: 300px; margin: 30px"
      />
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../common/icon";
import TaskInfoDrawer from "./Drawer/TaskInfoDrawer";
import TaskTypeDrawer from "./Drawer/TaskTypeDrawer";
import moment from "moment";
export default {
  components: {
    "task-info-drawer": TaskInfoDrawer,
    "task-type-drawer": TaskTypeDrawer,
  },
  data() {
    return {
      addressModalVisiable: false,

      icons: icon,

      taskDetail: {},

      address: "",
    };
  },
  mounted() {
    this.taskId = this.$router.currentRoute.params.tid;
    this.getDetail();
  },
  computed: {
    RewardTypeFormat() {
      return ["未设置", "按小时", "按天", "按月", "面议"][
        this.taskDetail.reward_type
      ];
    },
  },
  methods: {
    ...mapActions({
      taskSetAddressArrAction: "taskSetAddressArr",
      taskGetTaskDetailAction: "taskGetTaskDetail",
    }),
    getDetail() {
      this.taskGetTaskDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
      })
        .then((res) => {
          res.create_time =
            moment(res.create_time).format("YYYY/MM/DD HH:mm") ==
            "1900/01/01 00:00"
              ? "-"
              : moment(res.create_time).format("YYYY/MM/DD HH:mm");
          res.start_time =
            moment(res.start_time).format("YYYY/MM/DD") == "1900/01/01"
              ? "-"
              : moment(res.start_time).format("YYYY/MM/DD");
          res.end_time =
            moment(res.end_time).format("YYYY/MM/DD") == "1900/01/01"
              ? "-"
              : moment(res.end_time).format("YYYY/MM/DD");
          res.address_arr = JSON.parse(res.address_arr);
          this.taskDetail = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    edit() {
      this.$refs.taskInfoDrawer.open(
        this.taskDetail.description,
        this.taskDetail.start_time,
        this.taskDetail.end_time
      );
    },
    updateType() {
      this.$refs.taskTypeDrawer.open(this.taskDetail.reward_type);
    },
    addAddress() {
      this.address = "";
      this.addressModalVisiable = true;
    },
    addAddressComplete() {
      this.taskDetail.address_arr.push(this.address);
      this.taskSetAddressArrAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
        address_arr: this.taskDetail.address_arr,
      })
        .then((res) => {
          this.getDetail();
          this.addressModalVisiable = false;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    deleteAddress(index) {
      this.taskDetail.address_arr.splice(index, 1);
      this.taskSetAddressArrAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
        address_arr: this.taskDetail.address_arr,
      })
        .then((res) => {
          this.getDetail();
          this.addressModalVisiable = false;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.task-info-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 50px;
}
.task-info-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.task-info-desc-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
}
.task-info-address-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}
.task-info-address {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #f4f4f4;
}
.task-info-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>