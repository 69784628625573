<template>
  <my-drawer ref="taskDrawer" title="报酬方式">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <Select v-model="currentType" style="width: 300px">
          <Option
            v-for="item in typeList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MyDatePicker from "../../../components/Form/MyDatePicker";
import MyTextArea from "../../../components/Form/MyTextArea";
import MyDrawer from "../../../components/MyDrawer";
import moment from "moment";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-textarea": MyTextArea,
    "my-datepicker": MyDatePicker,
  },
  data() {
    return {
      taskId: 0,
      currentType: 1,
      typeList: [
        {
          value: 1,
          label: "按小时",
        },
        {
          value: 2,
          label: "按天",
        },
        {
          value: 3,
          label: "按月",
        },
        {
          value: 4,
          label: "面议",
        },
      ],
    };
  },
  mounted() {
    this.taskId = this.$router.currentRoute.params.tid;
  },
  methods: {
    ...mapActions({
      taskSetRewardTypeAction: "taskSetRewardType",
    }),
    open(type) {
      this.currentType = type;
      this.$refs.taskDrawer.open();
    },
    submit() {
      this.taskSetRewardTypeAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
        reward_type: this.currentType,
      })
        .then((res) => {
          this.$emit("refresh");
          this.$refs.taskDrawer.close();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
</style>