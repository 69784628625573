<template>
  <my-drawer ref="taskDrawer" title="任务详情">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-textarea ref="formName" :rows="4" title="描述" v-model="desc" />

        <my-datepicker
          ref="formStartTime"
          title="开始时间"
          v-model="start_time"
        />

        <my-datepicker ref="formEndTime" title="结束时间" v-model="end_time" />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MyDatePicker from "../../../components/Form/MyDatePicker";
import MyTextArea from "../../../components/Form/MyTextArea";
import MyDrawer from "../../../components/MyDrawer";
import moment from "moment";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-textarea": MyTextArea,
    "my-datepicker": MyDatePicker,
  },
  data() {
    return {
      taskId: 0,
      desc: "",
      start_time: new Date(),
      end_time: new Date(),
    };
  },
  mounted() {
    this.taskId = this.$router.currentRoute.params.tid;
  },
  methods: {
    ...mapActions({
      taskSetDescriptionAction: "taskSetDescription",
    }),
    open(desc, start_time, end_time) {
      this.desc = desc;
      this.start_time = start_time;
      this.end_time = end_time;
      this.$refs.taskDrawer.open();
    },
    submit() {
      this.taskSetDescriptionAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
        desc: this.desc,
        start_time: moment(this.start_time).format("YYYY/MM/DD 00:00:00"),
        end_time: moment(this.end_time).format("YYYY/MM/DD 23:59:59"),
      })
        .then((res) => {
          this.$emit("refresh");
          this.$refs.taskDrawer.close();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
</style>